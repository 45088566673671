<script lang="ts" setup>
const { color = '#F2F2F1' } = defineProps<{
  color?: string
}>()
</script>

<template>
  <svg data-ui="IconPathFinder" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M18 3C18 3.82843 17.3284 4.5 16.5 4.5C15.6716 4.5 15 3.82843 15 3C15 2.17157 15.6716 1.5 16.5 1.5C17.3284 1.5 18 2.17157 18 3Z" :stroke="color" />
    <path d="M5 17C5 17.8284 4.32843 18.5 3.5 18.5C2.67157 18.5 2 17.8284 2 17C2 16.1716 2.67157 15.5 3.5 15.5C4.32843 15.5 5 16.1716 5 17Z" :stroke="color" />
    <path d="M11 10C11 10.8284 10.3284 11.5 9.5 11.5C8.67157 11.5 8 10.8284 8 10C8 9.17157 8.67157 8.5 9.5 8.5C10.3284 8.5 11 9.17157 11 10Z" :stroke="color" />
    <path d="M15.5 3H6H5.5C3.567 3 2 4.567 2 6.5V6.5C2 8.433 3.567 10 5.5 10H6H6.5M4 17H13H14C15.933 17 17.5 15.433 17.5 13.5V13.5C17.5 11.567 15.933 10 14 10H13M5.82619 7.8791L11.6217 6.32619L13.1747 12.1217L7.3791 13.6747L5.82619 7.8791Z" :stroke="color" />
  </svg>
</template>
