<script setup lang="ts">
defineProps<{ expanded: boolean }>()
const auth = useAuth()

const isLoggingOut = ref(false)
const slideoverMenu = ref<string | null>(null)

const closeSlideoverOnMouseover = {
  mouseover() {
    slideoverMenu.value = null
  },
}

const links = computed(() => {
  return [
    [
      {
        icon: 'i-heroicons-home',
        to: auth.dashboardPath,
        label: 'Home',
        on: closeSlideoverOnMouseover,
      },
      {
        icon: resolveComponent('IconToolsAndServices'),
        label: 'Tools & Services',
        key: 'tools-and-services',
        on: {
          mouseover() {
            slideoverMenu.value = 'tools-and-services'
          },
        },
      },
      {
        icon: 'i-heroicons-academic-cap',
        label: 'Forgd Academy',
        key: 'forgd-academy',
        on: {
          mouseover() {
            slideoverMenu.value = 'forgd-academy'
          },
        },
      },
      {
        label: 'Schedule a Consultation',
        to: 'https://calendly.com/forgd/forgd-consultation',
        external: true,
        icon: 'i-heroicons-calendar-days',
        on: closeSlideoverOnMouseover,
      },
    ],
    [
      {
        icon: 'i-heroicons-information-circle',
        to: 'https://forgd.com/about',
        label: 'About Forgd',
        external: true,
        on: closeSlideoverOnMouseover,
      },
      {
        icon: 'i-heroicons-chat-bubble-oval-left-ellipsis',
        to: '/support',
        label: 'Support',
        on: closeSlideoverOnMouseover,
      },
      {
        icon: 'i-heroicons-cog-8-tooth',
        to: '/settings',
        label: 'Account Settings',
        on: closeSlideoverOnMouseover,
      },
      {
        icon: 'i-heroicons-arrow-right-start-on-rectangle',
        label: 'Logout',
        loading: isLoggingOut.value,
        on: {
          click: logout,
          ...closeSlideoverOnMouseover,
        },
      },
    ],
  ]
})

async function logout() {
  isLoggingOut.value = true
  await auth.logout()
  isLoggingOut.value = false
}
</script>

<template>
  <div
    data-el="AppMenuExpandable"
    class="flex-shrink-0 border-r bg-forgd-primary-900 font-display max-h-screen sticky top-0"
    :style="{ zIndex: 2 }"
  >
    <nav
      class="flex flex-col h-full justify-between divide-y divide-gray-700 max-h-screen"
      :class="expanded ? 'w-[260px]' : 'w-[52px]'"
      aria-label="Site Navigation"
    >
      <div class="min-h-[80px] flex items-center justify-center w-full">
        <NuxtLink to="/">
          <IconForgd :expanded="expanded" />
        </NuxtLink>
      </div>
      <div class="py-4 flex-grow px-2 max-h-full overflow-y-auto min-height-[93px] dark-scrollbar">
        <AppMenuExpandableNavigationList
          :items="links[0]"
          :expanded="expanded"
          :slideover-menu="slideoverMenu"
        />
      </div>
      <div class="py-4 px-2 opacity-70">
        <AppMenuExpandableNavigationList
          :items="links[1]"
          :expanded="expanded"
          :slideover-menu="slideoverMenu"
        />
      </div>
    </nav>
  </div>
  <AppMenuSlideover v-model="slideoverMenu" :style="{ zIndex: 1 }" :expanded="expanded" />
</template>
